import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  productPercentage: {
    fontSize: '13px !important',
    color: 'green',
    fontWeight: '600 !important',
    marginRight: '6px !important',
    lineHeight: '26px !important',
    verticalAlign: 'bottom'
  },
  productPercentageDatabaseCard: {
    fontSize: '13px !important',
    color: '#E00000 !important',
    fontWeight: '600 !important',
    marginRight: '6px !important',
    lineHeight: '18px !important',
    verticalAlign: 'bottom'
  }
}));
