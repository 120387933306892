import { makeStyles } from '@mui/styles';
import colors from '../../../../utils/colors';

export default makeStyles(() => ({
  seeOnAmazonButton: {
    // backgroundColor: '#ffd812!important',
    // color: '#000 !important',
    padding: '4px 10px !important',
    marginTop: '10px !important',
    marginBottom: '10px !important'
  },
  actionButton: {
    backgroundColor: 'white !important',
    padding: '4px 10px !important',
    marginTop: '10px !important',
    marginBottom: '10px !important',
    marginLeft: '10px !important'
  },
  chip: {
    marginRight: '4px !important'
  },
  couponSwitch: {
    margin: '10px'
  },
  chipSSIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  chipPromoIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  chipCouponIcon: {
    color: 'white !important',
    fontSize: '13px !important',
    marginLeft: '6px !important'
  },
  chipSS: {
    backgroundColor: '#00796b !important',
    // backgroundColor: '#D14900 !important',
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  chipCoupon: {
    // backgroundColor: '#5c6bc0 !important',
    backgroundColor: '#1565c0 !important',
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  chipPromo: {
    backgroundColor: `${colors.promotion} !important`,
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  chipWrapper: {
    marginBottom: '0.35em',
    marginTop: '0.35em',
    display: 'block',

    '@media (max-width: 48rem)': {
      display: 'block'
    }
  }
}));
