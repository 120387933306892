/* eslint-disable react/no-array-index-key */
import React from 'react';
import { ButtonBase, Box } from '@mui/material';
import { DealPostType } from '@types';
import { parseTitle } from '@utils/index';
import { getScaledImage } from '@utils/imageUtils';
import { maxImageHeightAndWidth } from '../../layoutSettings';
import useStyles from './styles';

const CardImage = (props: DealPostType) => {
  const { title, image, image240Url, image240Height, image240Width } = props;

  const image240UrlWebp = image240Url?.replace('.jpg', '.webp');
  const classes = useStyles();

  const renderFullWith = () => {
    return (
      <img
        alt={parseTitle(title)}
        src={(image240UrlWebp || image)?.replace('http:', 'https:')}
        className={classes.imageDb}
        style={{
          // width 100%
          width: '100%',
          // width: getScaledImage(image240Width, image240Height, maxWidth).width,
          // height: getScaledImage(image240Width, image240Height, maxWidth)
          // .height,
          objectFit: 'contain',
          marginTop: '8px',
          marginBottom: '0px !important'
        }}
      />
    );
  };

  const renderImage = (maxWidth: number) => {
    return (
      <img
        alt={parseTitle(title)}
        src={(image240Url || image)?.replace('http:', 'https:')}
        className={classes.imageDb}
        style={{
          width: getScaledImage(image240Width, image240Height, maxWidth).width,
          height: getScaledImage(image240Width, image240Height, maxWidth)
            .height,
          objectFit: 'contain',
          marginTop: '8px'
        }}
      />
    );
  };

  return (
    <ButtonBase
      sx={{
        width: '100%',
        // if larger than 450px
        '@media (min-width: 450px)': {
          minHeight: `${maxImageHeightAndWidth}px`,
          height: `${maxImageHeightAndWidth + 20}px`
        }
      }}
      data-testid="button-base"
    >
      <Box
        className={classes.imageMobileWrapperDb}
        data-testid="image-sm-mobile-wrapper"
        sx={{
          margin: '8px auto 8px auto',
          maxWidth: `100%`,
          // if larger than 450px do not show
          display: 'block',
          '@media (min-width: 400px)': {
            display: 'none'
          },
          '@media (min-width: 450px)': {
            display: 'none'
          }
        }}
      >
        {renderFullWith()}
      </Box>
      <Box
        className={classes.imageMobileWrapperDb}
        data-testid="image-mobile-wrapper"
        sx={{
          margin: '8px auto 8px auto !important',
          display: 'none',
          // if larger than 450px show
          '@media (min-width: 450px)': {
            display: 'block'
          }
        }}
      >
        {renderImage(maxImageHeightAndWidth)}
      </Box>
      <Box
        data-testid="image-sm-mobile-wrapper"
        className={`${classes.imageMobileWrapperDb} ${classes.imageMobileWrapperDbMobile}`}
        sx={{
          margin: '8px auto 8px auto',
          maxWidth: `150px`,
          minWidth: `150px`,
          // if larger than 450px do not show
          display: 'none',
          '@media (min-width: 400px) and (max-width: 450px)': {
            display: 'flex'
          },
          // smaller than 400px
          '@media (max-width: 400px)': {
            margin: '8px auto 0 auto'
          }
        }}
      >
        {renderImage(120)}
      </Box>
    </ButtonBase>
  );
};

export default CardImage;
