import React from 'react';
import classnames from 'classnames';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import useStyles from './styles';

const SkeletonInnerDealCard = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.imageWrapper}>
        <div className={classes.imageMobileWrapper}>
          <Skeleton
            component="div"
            variant="rectangular"
            className={`${classes.imageMobile} ${classes.skeletonImageMobile}`}
            width={140}
            height={140}
          />
        </div>
      </div>
      <CardContent
        classes={{
          root: `${classes.cardContent} ${classes.skeletonCardContent}`
        }}
      >
        <Skeleton
          className={classes.skeletonText}
          variant="rectangular"
          height={16}
        />
        <Skeleton
          className={classes.skeletonText}
          variant="rectangular"
          height={16}
          width="75%"
        />
        <Skeleton
          className={classes.skeletonText}
          variant="rectangular"
          width="40%"
        />
        <Skeleton
          className={classes.skeletonTextDate}
          variant="rectangular"
          height={14}
          width="60%"
        />
      </CardContent>
      <CardActions classes={{ root: classes.actionButtons }}>
        <Skeleton
          className={classes.skeletonTextActions}
          variant="rectangular"
          height={30}
          width={78}
        />
        <Skeleton
          className={classes.skeletonButton}
          variant="circular"
          height={34}
          width={34}
        />
        <Skeleton
          className={classes.skeletonButton}
          variant="circular"
          height={34}
          width={34}
        />
      </CardActions>
    </>
  );
};

const SkeletonDealCard = () => {
  const classes = useStyles();
  return (
    <Card
      key="test"
      raised
      classes={{
        root: classnames(classes.cardRoot, classes.skeletonCardRoot)
      }}
    >
      <SkeletonInnerDealCard />
    </Card>
  );
};

export default SkeletonDealCard;
