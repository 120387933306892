import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { DealPostType } from '@types';
import useStyles from './styles';

const SearchFilterField = ({
  setIsFiltering,
  dealsWithASIN,
  searchFilter,
  setSearchFilter
}: {
  setIsFiltering: (value: boolean) => void;
  dealsWithASIN: DealPostType[];
  searchFilter: string;
  setSearchFilter: (value: string) => void;
}) => {
  const classes = useStyles();
  return (
    <TextField
      id="standard-basic"
      size="small"
      fullWidth
      placeholder={
        (dealsWithASIN || [])?.length > 0
          ? `Search ${dealsWithASIN.length} deals...`
          : 'Search...'
      }
      variant="outlined"
      value={searchFilter}
      className={classes.searchFilter}
      onChange={(e) => {
        setSearchFilter(e.target.value);
        if (e.target.value !== '') {
          setIsFiltering(true);
        } else {
          setIsFiltering(false);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment:
          searchFilter === '' ? null : (
            <InputAdornment
              position="end"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                setSearchFilter('');
                setIsFiltering(false);
              }}
            >
              <CloseIcon />
            </InputAdornment>
          )
      }}
    />
  );
};

export default SearchFilterField;
