import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import config from '@configFile';
import { useGetCouponDealsByCode } from '@hooks/useDeals';
import { DealPostType } from '@types';
import SummaryListDeal from '../SummaryListDeal';
import colors from '../../../../utils/colors';

const CouponDeals = ({
  currentASIN,
  promoCode,
  defaultExpanded = true
}: {
  currentASIN: string;
  promoCode: string;
  defaultExpanded?: boolean;
}) => {
  const { data: deals, isLoading } = useGetCouponDealsByCode(promoCode);

  if (isLoading) {
    return null;
  }

  const filteredDeals = (deals || []).filter(
    (deal: any) => deal.ASIN !== currentASIN
  );

  if (!deals || filteredDeals.length === 0) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: 8
      }}
    >
      <Accordion
        defaultExpanded={defaultExpanded}
        sx={{
          border: `1px solid ${colors.coupon}`,
          borderRadius: '4px',
          background: colors.coupon
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              sx={{
                color: 'white'
              }}
            />
          }
          sx={{
            background: colors.coupon,
            borderRadius: '4px'
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: 'white',
              fontWeight: 'bold'
            }}
            variant="subtitle2"
          >
            Alternative deals for this one time use coupon
            {filteredDeals.length > 0
              ? ` (${filteredDeals.length} deal${
                  filteredDeals.length > 1 ? 's' : ''
                })`
              : ''}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: 'white'
          }}
        >
          {(filteredDeals || []).map((deal: DealPostType) => (
            <SummaryListDeal
              key={deal.ASIN}
              deal={deal}
              tag={config.AFFILIATE_TAGS.JD_BRAND}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CouponDeals;
