import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { ButtonWrapper } from '@pages/Deals/components/DatabaseCard';
// import { maxImageHeightAndWidth } from './DatabaseCard/layoutSettings';
import useStyles from './DatabaseCard/styles';
import useStyles2 from './styles';
import useImageStyles from './DatabaseCard/components/CardImage/styles';

const SkeletonDatabaseCard = () => {
  const classes = useStyles();
  const classes2 = useStyles2();
  const imageClasses = useImageStyles();
  return (
    <Card
      key="test"
      raised
      classes={{
        root: classnames(classes.cardRootDb, classes2.skeletonCardRoot)
      }}
    >
      <>
        <Box>
          <Box className={classes.outerContentWrapper}>
            <Box
              position="relative"
              margin="0 auto"
              className={classes.cardImage}
              // sx={{
              //   '@media (min-width: 400px) and (max-width: 450px)': {
              //     margin: '0px'
              //   }
              // }}
            >
              <Box
                className={imageClasses.imageMobileWrapperDb}
                sx={{
                  '@media (max-width: 400px)': {
                    display: 'flex'
                  }
                }}
              >
                <Skeleton
                  component="div"
                  variant="rectangular"
                  className={`${imageClasses.imageDb} ${imageClasses.imageMobileWrapperDbMobile}`}
                  // width={maxImageHeightAndWidth}
                  // height={maxImageHeightAndWidth}
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.11) !important',
                    marginTop: '8px',
                    '@media (min-width: 400px) and (max-width: 450px)': {
                      margin: '0px !important'
                    }
                  }}
                />
              </Box>
            </Box>
            <CardContent
              classes={{
                root: classnames(
                  classes.cardContent,
                  classes.skeletonCardContent
                )
              }}
            >
              <Skeleton
                className={classes.skeletonText}
                variant="rectangular"
                height={10}
                width="80%"
              />
              <Skeleton
                className={classes.skeletonText}
                variant="rectangular"
                height={16}
                width="95%"
              />
              <Skeleton
                className={classes.skeletonText}
                variant="rectangular"
                height={16}
                width="45%"
              />
              <Skeleton
                className={classes.skeletonText}
                variant="rectangular"
                width="40%"
              />
              <Skeleton
                className={classes.skeletonTextDate}
                variant="rectangular"
                height={10}
                width="50%"
              />
              <Box marginTop="4px" display="flex">
                <Skeleton
                  className={classes.skeletonText}
                  variant="rounded"
                  height={20}
                  width={95}
                  sx={{
                    borderRadius: '16px',
                    marginRight: '4px',
                    display: 'inline-flex'
                  }}
                />
                <Skeleton
                  className={classes.skeletonText}
                  variant="rounded"
                  height={20}
                  width={66}
                  sx={{
                    borderRadius: '16px',
                    display: 'inline-flex'
                  }}
                />
              </Box>
            </CardContent>
          </Box>
          <CardActions classes={{ root: classes.actionButtons }}>
            {_.times(3, (i) => (
              <ButtonWrapper
                key={i}
                button={
                  <Skeleton
                    className={classes2.skeletonButton}
                    variant="circular"
                    height={34}
                    width={34}
                  />
                }
                label={
                  <Skeleton
                    sx={{
                      marginTop: '8px'
                    }}
                    className={classes2.skeletonButton}
                    variant="rectangular"
                    height={10}
                    width={30}
                  />
                }
              />
            ))}
          </CardActions>
        </Box>
      </>
    </Card>
  );
};

export default SkeletonDatabaseCard;
