import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  dialogPaper: {
    overflow: 'visible!important',
    '@media (max-width: 540px)': {
      width: 'calc(100% - 8px) !important',
      margin: '8px!important'
    }
  },
  seeOnAmazonButton: {
    padding: '4px 10px !important'
  }
}));
