/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TocIcon from '@mui/icons-material/Toc';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LaunchIcon from '@mui/icons-material/Launch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import IconButton from '@mui/material/IconButton';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useGetUserData } from '@hooks/useGetUserData';
import { useSnackbar } from '@hooks/useSnackbar';
import { useUpdateDealsByBrand } from '@hooks/useDeals';
import DeleteDeal from '@components/AdminActions/DeleteDeal';
import SendMailing from '@components/SendMailing';
import ChangeInfo from '@components/AdminActions/ChangeInfo';
import ReportInaccurate from '@components/ReportInaccurate';
import { parseTitle, getPostPrice } from '@utils/index';
import { iconButtonHoverRaiseStyle } from '@utils/styles';
import CompareDetails from '@components/AdminActions/CompareDetails';
import { ImageSwitchingModalButton } from '@components/AdminActions/ImageSwitchingModal';
import CopyBlogLink from '@components/AdminSlugActions/components/CopyBlogLink';
import MoveToToyDeals from '@components/AdminActions/components/MoveToToyDeals';
import { DealPostType, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import { dealsAction } from '../../../../../../actions';

const DealCardActionMenuItem = ({
  text,
  icon,
  onClick,
  disabled = false
}: {
  text: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <MenuItem onClick={onClick} disabled={disabled}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </MenuItem>
);

interface DealCardActionsType extends DealPostType {
  buttonSize?: 'small' | 'medium';
}

const DealCardActions = (props: DealCardActionsType) => {
  const {
    title,
    ASIN,
    couponPercentage,
    couponFixed,
    parentASIN,
    slug,
    brand,
    buttonSize = 'medium'
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: user } = useGetUserData();
  const { showMessage } = useSnackbar();
  const { mutate: updateDealsByBrand } = useUpdateDealsByBrand();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const isAdmin = !!user?.isAdmin;

  const showVariationsButton =
    (parentASIN && parentASIN !== ASIN && !couponFixed && !couponPercentage) ||
    isAdmin;

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAmazonDealDetailsClick = () => {
    dispatch({
      type: DEALS_UPDATE_RECEIPT_DEAL,
      receiptDeal: props
    });
    dispatch(dealsAction.getDealSummary(props.ASIN));
    history.push(`/deal/${props.ASIN}`);
    handleCloseMenu();
  };

  const adminActions = () => {
    const actionsAdmin = [
      <ChangeInfo
        key="change-info"
        ASIN={ASIN}
        onCloseCallback={handleCloseMenu}
      />,
      <CopyBlogLink
        {...props}
        key="copy-blog-link"
        handleClose={handleCloseMenu}
      />,
      <ImageSwitchingModalButton
        key="admin-set-image"
        ASIN={ASIN}
        closeCallback={handleCloseMenu}
      />,
      <CompareDetails
        key="compare"
        ASIN={ASIN}
        onCloseCallback={handleCloseMenu}
      />,
      <SendMailing
        ASIN={ASIN}
        key="mailung"
        subject={`${parseTitle(title)}${getPostPrice(props)}`}
        appearance="menuItem"
        onCloseCallback={handleCloseMenu}
      />
    ];

    if (brand) {
      actionsAdmin.push(
        <MenuItem
          key="recheck-brand"
          onClick={() => handleRecheckBrandClick(brand)}
        >
          <ListItemIcon>
            <AssignmentTurnedInIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Recheck {brand} Brand Deals</ListItemText>
        </MenuItem>
      );
    }

    return actionsAdmin;
  };

  const handleRecheckBrandClick = (brand: string) => {
    updateDealsByBrand(
      { brand },
      {
        onSuccess: (data) => {
          handleCloseMenu();
          showMessage(
            `Rechecking ${data?.rechecking || 0} ${brand} brand ${
              data?.rechecking > 1 ? 'deals' : 'deal'
            }...`
          );
        }
      }
    );
  };

  return (
    <>
      <Tooltip title="More deal actions">
        <IconButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuClick}
          size={buttonSize}
          sx={{
            ...iconButtonHoverRaiseStyle
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        {isAdmin && adminActions()}
        {!isAdmin && (
          <DealCardActionMenuItem
            text="Amazon Deal Details"
            icon={<ReceiptLongIcon fontSize="small" />}
            onClick={handleAmazonDealDetailsClick}
          />
        )}
        <ReportInaccurate
          ASIN={ASIN}
          closeDialog={(success) => {
            if (success) {
              showMessage(
                'Thank you for your report. Our team will recheck this deal for accuracy.'
              );
              handleCloseMenu();
            }
          }}
          variant="listItem"
        />
        {showVariationsButton && (
          <MenuItem
            key="see-variations"
            onClick={() => {
              history.push(`/variations?ASIN=${parentASIN || ASIN}`);
              // wait a quarter second and then smooth scroll to top
              setTimeout(() => {
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }, 250);
            }}
          >
            <ListItemIcon>
              <TocIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Search Variations</ListItemText>
          </MenuItem>
        )}
        <DealCardActionMenuItem
          key="go-to-blog-post"
          text="Go To Blog Post"
          icon={<LaunchIcon fontSize="small" />}
          onClick={() => history.push(`/blog/${slug}`)}
        />
        {isAdmin && (
          <MoveToToyDeals ASIN={ASIN} onCloseCallback={handleCloseMenu} />
        )}
        {isAdmin && (
          <DeleteDeal ASIN={ASIN} onCloseCallback={handleCloseMenu} />
        )}
      </Menu>
    </>
  );
};

export default DealCardActions;
