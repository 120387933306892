import { makeStyles } from '@mui/styles';
import colors from '@utils/colors';
import { getCurrentDealEventStrObj } from '@utils/index';

const currentEventObj = getCurrentDealEventStrObj();

export default makeStyles(() => ({
  spinner: {
    display: 'inline-block'
  },
  productTitle: {
    display: '-webkit-box !important',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px !important',
    '@media (min-width: 1700px)': {
      // larger text for larger screens
      fontSize: '14px !important'
    }
  },
  cardText: {
    textDecoration: 'none',
    color: colors.jdbPurple,

    '&:hover': {
      color: 'black'
    }
  },
  skeletonCardRoot: {
    display: 'block !important'
  },
  productPercentage: {
    fontSize: '13px !important',
    color: 'green',
    fontWeight: '600 !important',
    marginRight: '6px !important',
    lineHeight: '26px !important',
    verticalAlign: 'bottom'
  },
  productPrice: {
    display: '-webkit-box !important',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px !important',
    color: '#333',
    fontWeight: '600 !important',

    '@media (max-width: 48rem)': {
      WebkitLineClamp: 'unset !important'
    }
  },
  skeletonText: {
    marginBottom: 4
  },
  skeletonTextDate: {
    marginBottom: 6
  },
  skeletonTextActions: {
    margin: '8px 0 8px 8px'
  },
  skeletonButton: {
    marginRight: '0px'
  },
  cardContent: {
    display: 'table',
    padding: '8px 8px 36px 8px !important',
    // less than 400 px
    '@media (max-width: 380px)': {
      padding: '8px 8px 40px 8px !important'
    }
  },
  skeletonCardContent: {
    display: 'inline-block',
    minWidth: 'calc(100% - 160px)',
    '@media (max-width: 380px)': {
      minWidth: '94%'
    }
  },
  imageMobileWrapper: {
    width: 140,
    height: 140,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    margin: '16px auto 8px auto',
    paddingLeft: '4px'
  },
  image: {
    maxHeight: 140,
    display: 'none'
  },
  imageMobile: {
    maxWidth: 140,
    maxHeight: 140,
    display: 'block',
    borderRadius: '3px'
  },
  skeletonImageMobile: {
    willChange: 'transform',
    transform: 'scale(1, 1)',
    '@media (max-width: 380px)': {
      marginBottom: '22px !important'
    }
  },
  imageWrapper: {
    maxHeight: 140,
    minHeight: 140,
    margin: '0 auto',
    textAlign: 'center',
    borderRadius: '3px',
    // minWidth: '100%',
    minWidth: 140,
    width: 140,
    display: 'inline-block',
    float: 'left',
    // less than 380px
    '@media (max-width: 380px)': {
      float: 'none !important',
      display: 'flex !important',
      width: '100% !important'
    }
  },
  actionButton: {
    backgroundColor: 'white !important'
  },
  actionButtons: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    textAlign: 'right',
    padding: '8px 8px 0 8px !important'
  },
  mobileDate: {
    fontSize: '11px !important',
    color: '#333'
  },
  mobileOnly: {
    display: 'inline-block'
  },
  cardRoot: {
    transition: 'none !important',
    animation: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    // Ensure cards stretch to the same height
    flex: '1 0 auto',
    position: 'relative',
    maxWidth: 'calc(50% - 14px)',
    minWidth: 'calc(50% - 14px)',
    minHeight: 160,
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    // 2 per row
    '@media (min-width: 782px) and (max-width: 1189px)': {
      '&:nth-of-type(2n)': {
        marginLeft: 0
      }
    },
    // 1 per row
    '@media (max-width: 781px)': {
      maxWidth: 'calc(100% - 18px)',
      minWidth: 'calc(100% - 18px)',
      minHeight: 'initial'
    },
    // 3 per row
    '@media (min-width: 1190px)': {
      maxWidth: 'calc(33.3% - 12px)',
      minWidth: 'calc(33.3% - 12px)',
      // nth type 2 of 3 and 3 of 3
      '&:nth-of-type(3n)': {
        marginLeft: 0
      },
      '&:nth-of-type(3n - 1)': {
        marginLeft: 0
      }
    }
  },
  chip: {
    marginRight: '4px !important'
  },
  bestSeller: {
    border: `2px solid ${colors.trending}`,
    boxSizing: 'border-box'
  },
  favorite: {
    border: `2px solid ${colors.favorite}`,
    boxSizing: 'border-box'
  },
  eventDeal: {
    border: `2px solid ${
      currentEventObj?.dealHighlightColor || colors.jdbPurple
    }`,
    boxSizing: 'border-box'
  },

  chipPromo: {
    backgroundColor: `${colors.promotion} !important`,
    color: 'white !important',
    marginTop: '0.35em !important'
  },
  chipWrapper: {
    marginBottom: '0.35em',
    marginTop: '0.35em',
    display: 'block',

    '@media (max-width: 48rem)': {
      display: 'block'
    }
  }
}));
