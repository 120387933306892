/* eslint-disable react/no-array-index-key */
import React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { formatPrice, logPostHogEvent } from '@utils/index';

import trackUse from '@utils/trackUse';
import { DealPostType } from '@types';
import useStyles from './styles';

const PromoFixedChip = (props: DealPostType) => {
  const { ASIN, promoFixed } = props;

  const classes = useStyles();

  const [promoChipAnchorEl, setPromoChipAnchorEl] =
    React.useState<HTMLElement | null>(null);

  const promoChipOpen = Boolean(promoChipAnchorEl);

  const handlePromoChipClick = (event: React.MouseEvent<HTMLElement>) => {
    setPromoChipAnchorEl(event.currentTarget);
    trackUse({
      item: 'open-promo-chip',
      value: ASIN,
      type: 'CLICK'
    });

    logPostHogEvent('open-promo-chip', {
      ASIN,
      type: 'CLICK'
    });
  };

  const handlePromoChipClose = () => {
    setPromoChipAnchorEl(null);
  };

  return (
    <span key="pr-chip">
      <Chip
        sx={{
          fontSize: '11px'
        }}
        id={`promo_chip_${ASIN}`}
        className={`${classes.chip} ${classes.chipPromo}`}
        size="small"
        label={`${formatPrice(promoFixed)} Discount`}
        onClick={handlePromoChipClick}
      />
      <Popover
        id={`promo_chip_${ASIN}`}
        open={promoChipOpen}
        anchorEl={promoChipAnchorEl}
        onClose={handlePromoChipClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <div>
          <Typography sx={{ p: 2 }} component="h3">
            Discount available at checkout
          </Typography>
          <Typography sx={{ p: 2 }}>
            {`There's a $${promoFixed.toFixed(
              2
            )} discount automatically available at checkout for this item! Look for the words "Save ${formatPrice(
              promoFixed
            )} at checkout" below the product price.`}
          </Typography>
          <Typography sx={{ p: 2 }} />
        </div>
      </Popover>
    </span>
  );
};

export default PromoFixedChip;
