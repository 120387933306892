import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import { formatDistance } from 'date-fns';

import { CarouselRibbon } from '@components/Ribbon';
import config from '@configFile';
import { DealPostType, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import CouponChip from '@components/CouponChip';
import FavoriteButton from '@components/FavoriteButton';
import NewTooltip from '@components/NewTooltip';
import { MonetizingLink } from '@components/index';
import {
  parseTitle,
  getPostPrice,
  getProductLink,
  logPostHogEvent,
  getCurrentDealEventStrObj
} from '@utils/index';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import PromoFixedChip from '@components/PromoFixedChip';
import trackUse from '@utils/trackUse';
import { useGetFavorites } from '@hooks/useFavorites';
import { useGetUserSettings, defaultUISettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { dealsAction } from '../../../../actions';
import useStyles from '../styles';
import DealCardActions from './components/DealCardActions';
import PercentageDiscount from './components/PercentageDiscount';

interface DealCardProps extends DealPostType {
  onlyCoupons: boolean;
  toggleOnlyCoupons: () => void;
  tag?: string;
}

const DealInnerCard = memo((props: DealCardProps) => {
  const {
    title,
    image,
    image240Url,
    postDate,
    ASIN,
    finalPrice,
    ss,
    couponPercentage,
    couponFixed,
    promoFixed,
    tag
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: userFavorites } = useGetFavorites();
  const { data: userSettings } = useGetUserSettings();
  const { data: userData } = useGetUserData();
  const affiliateTag = tag || config.AFFILIATE_TAGS.DATABASE_CONTROL;

  const shouldShowRibbons = useMemo(() => {
    return !!userData && userSettings
      ? !!userSettings.databaseShowRibbons
      : defaultUISettings.databaseShowRibbons;
  }, [userSettings]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const link = useMemo(
    () => getProductLink(ASIN, affiliateTag),
    [ASIN, affiliateTag]
  );

  const handleDetailsClick = useCallback(() => {
    dispatch({ type: DEALS_UPDATE_RECEIPT_DEAL, receiptDeal: props });
    dispatch(dealsAction.getDealSummary(ASIN));
    history.push(`/deal/${ASIN}`);
    trackUse({ item: 'details-button-click', value: ASIN, type: 'CLICK' });
    logPostHogEvent('details-button-click', {
      item: 'details-button-click',
      value: ASIN,
      type: 'CLICK'
    });
  }, [dispatch, history, ASIN, props]);

  const getCardChips = useMemo(() => {
    const chips = [];

    if (couponPercentage || couponFixed) {
      chips.push(<CouponChip {...props} key="coupon-chip" />);
    }

    if (ss) {
      chips.push(<SubscribeSaveChip {...props} key="ss-chip" />);
    }

    if (promoFixed) {
      chips.push(<PromoFixedChip {...props} key="promo-chip" />);
    }

    return chips.length > 0 ? (
      <div className={classes.chipWrapper}>
        <PercentageDiscount {...props} />
        {chips}
      </div>
    ) : (
      <PercentageDiscount {...props} />
    );
  }, [
    couponPercentage,
    couponFixed,
    ss,
    promoFixed,
    classes.chipWrapper,
    props
  ]);

  const renderNewTooltip = useMemo(() => {
    return (
      <NewTooltip variant="caption" usePostDate deal={props} label="*New*" />
    );
  }, [props]);

  const shouldDisplayEventDeal = useMemo(() => {
    const currentEventObj = getCurrentDealEventStrObj();
    return (
      currentEventObj &&
      (props.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode]);

  const image240UrlWebp = image240Url?.replace('.jpg', '.webp');

  return (
    <>
      <Box>
        <MonetizingLink
          href={link}
          clickType="TEXT"
          tag={affiliateTag}
          item="deal-image-wrapper"
        >
          {shouldShowRibbons &&
            (props.isBestSeller || shouldDisplayEventDeal || isFavorite) && (
              <CarouselRibbon
                dealSummary={props}
                onlyShowImportant
                opacity={1}
                padding="1px 8px"
                fontSize="11px"
              />
            )}
          <ButtonBase className={classes.imageWrapper}>
            <div className={classes.imageMobileWrapper}>
              <img
                alt={title}
                src={(image240UrlWebp || image)?.replace('http:', 'https:')}
                className={classes.imageMobile}
              />
            </div>
          </ButtonBase>
        </MonetizingLink>
        <CardContent classes={{ root: classes.cardContent }}>
          <MonetizingLink
            href={link}
            clickType="TEXT"
            tag={affiliateTag}
            item="deal-card-content"
            className={classes.cardText}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className={classes.productTitle}
            >
              {`${parseTitle(title)}`}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              className={classes.productPrice}
            >
              {`${getPostPrice(props, false)}`}
            </Typography>
            <Typography className={classes.mobileDate}>
              {renderNewTooltip}
              {`Added ${formatDistance(new Date(postDate), new Date(), {
                addSuffix: true
              })}`}
            </Typography>
          </MonetizingLink>
          <Box>{getCardChips}</Box>
        </CardContent>
      </Box>
      <CardActions classes={{ root: classes.actionButtons }}>
        <Button
          key="btn-summary"
          className={classes.actionButton}
          size="small"
          variant="outlined"
          onClick={handleDetailsClick}
        >
          Details
        </Button>
        <FavoriteButton ASIN={ASIN} finalPrice={finalPrice} />
        <span className={classes.mobileOnly}>
          <DealCardActions {...props} />
        </span>
      </CardActions>
    </>
  );
});

const DealCard = memo((props: DealCardProps) => {
  const { ASIN } = props;
  const classes = useStyles();
  const { data: userFavorites } = useGetFavorites();
  const currentEventObj = getCurrentDealEventStrObj();

  const eventDealStyles = classes.eventDeal;
  const favoriteStyles = classes.favorite;
  const bestSellingStyles = classes.bestSeller;

  const showEventBorder = useMemo(() => {
    return (
      currentEventObj &&
      (props.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode, currentEventObj]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const cardClassName = useMemo(() => {
    return classnames(
      classes.cardRoot,
      { [bestSellingStyles]: props.isBestSeller },
      { [eventDealStyles]: showEventBorder },
      { [favoriteStyles]: isFavorite }
    );
  }, [
    classes.cardRoot,
    bestSellingStyles,
    eventDealStyles,
    favoriteStyles,
    props.isBestSeller,
    showEventBorder,
    isFavorite
  ]);

  return (
    <Card key={ASIN} raised classes={{ root: cardClassName }}>
      <DealInnerCard {...props} />
    </Card>
  );
});

export default DealCard;
