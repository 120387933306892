import { makeStyles } from '@mui/styles';
import { maxImageHeightAndWidth } from '../../layoutSettings';

export default makeStyles(() => ({
  imageMobileWrapperDb: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: '3px',
    marginBottom: '12px',
    marginTop: '8px',

    maxHeight: `unset`,
    margin: '8px auto 8px auto',
    // more than 450px
    '@media (min-width: 48rem)': {
      maxWidth: `${maxImageHeightAndWidth}px !important`
    },
    // if smaller than 400px
    '@media (max-width: 400px)': {
      display: 'block',
      margin: '8px auto 0px auto'
    }
  },
  imageDb: {
    display: 'block',
    borderRadius: '3px'
    // less than sm
    // '@media (max-width: 48rem)': {
    //   display: 'none'
    // }
  },
  imageMobileWrapperDbMobile: {
    transform: 'scale(1, 1)',
    width: '200px',
    height: '200px !important',
    '@media (max-width: 450px) and (min-width: 400px)': {
      minWidth: '120px !important',
      maxWidth: '120px !important',
      maxHeight: '120px !important',
      width: '120px',
      height: '120px !important'
    }
  }
}));
