import { makeStyles } from '@mui/styles';
import colors from '@utils/colors';

export default makeStyles(() => ({
  chip: {
    marginRight: '4px !important'
  },
  chipPromo: {
    backgroundColor: `${colors.promotion} !important`,
    color: 'white !important',
    marginTop: '0.35em !important'
  }
}));
