/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { getHelmetMeta, getSEOSchema } from '@utils/seo';
import PageContainer from '@components/PageContainer';
import { getPostPrefix, isPrimeDay as isPrimeDayFunc } from '@utils/index';

import { AppState } from '@types';
import Deals from './Deals';
// eslint-disable-next-line import/no-named-as-default

interface DealsContainerProps extends ReturnType<typeof mapStateToProps> {
  isPrimeDay?: boolean;
}

const DealsContainer = (props: DealsContainerProps) => {
  const { dealPageTitle, ogPath, ogImage, ogTitle, isPrimeDay } = props;
  const ogTitleWithPrefix = `${getPostPrefix()}${ogTitle}`;
  const image =
    ogImage || 'https://www.jungledealsblog.com/jd-images/page-deals/3.jpg';

  const pageTitle =
    isPrimeDay || isPrimeDayFunc()
      ? undefined
      : `${getPostPrefix()}${dealPageTitle}`;

  return (
    <PageContainer title={pageTitle} fullWidth isAdmin={false}>
      <Helmet
        title={ogTitleWithPrefix}
        meta={getHelmetMeta({
          title: ogTitleWithPrefix,
          image,
          description:
            'Discover the best Amazon deals with our comprehensive list of name-brand products featuring exclusive coupons and special offers. Save big on your favorite items with our daily updated discounts. ',
          url: ogPath
        })}
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify(
              getSEOSchema({
                title: ogTitleWithPrefix,
                image: [ogImage],
                description: ogTitle
              })
            )
          }
        ]}
      />
      <Deals isPrimeDay={isPrimeDay} />
    </PageContainer>
  );
};

const mapStateToProps = ({
  home: { dealPageTitle, ogPath, ogTitle, ogImage }
}: AppState) => ({
  dealPageTitle,
  ogPath,
  ogTitle,
  ogImage
});

export default connect(mapStateToProps)(DealsContainer);
