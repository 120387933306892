import { makeStyles } from '@mui/styles';
import colors from '@utils/colors';

export default makeStyles(() => ({
  action: {
    // display: '-webkit-box !important',
    // WebkitLineClamp: 3,
    // WebkitBoxOrient: 'vertical',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    fontSize: '13px !important',
    display: '-webkit-box !important',
    WebkitLineClamp: '2 !important' as any,
    WebkitBoxOrient: 'vertical !important' as any,
    // fontWeight: '700 !important',
    maxHeight: '40px !important' as any,
    overflow: 'hidden',
    // if more than 450px
    '@media (min-width: 48rem)': {
      minHeight: '40px !important' as any
    }
  },
  postTextPreview: {
    display: '-webkit-box !important',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative'
  },
  spinner: {
    display: 'inline-block'
  },
  postTextWrapper: {
    padding: '12px 8px 8px 8px',
    '@media (max-width: 48rem)': {
      padding: '12px 0px 8px 0px'
    }
  },
  action2LineMobile: {
    display: '-webkit-box !important',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '13px !important'
  },
  cardText: {
    textDecoration: 'none',
    color: colors.jdbPurple,

    '&:hover': {
      color: 'black'
    }
  },
  action2Line: {
    display: '-webkit-box !important',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px !important',
    color: '#333',
    fontWeight: '600 !important',

    '@media (max-width: 48rem)': {
      WebkitLineClamp: 'unset !important'
    }
  },
  avatarBox: {
    display: 'inline-block',
    marginRight: '12px',
    minWidth: '48px',
    '@media (max-width: 48rem)': {
      display: 'none'
    }
  },
  skeletonText: {
    marginBottom: 4
  },
  skeletonTextDate: {
    marginBottom: 6
  },
  skeletonTextActions: {
    margin: '8px 0 8px 8px'
  },
  cardContent: {
    padding: '8px !important',
    display: 'block',
    // is smaller than 450px and larger than 400px
    '@media (max-width: 48rem) and (min-width: 400px)': {
      padding: '8px 8px 0px 8px'
    }
  },
  skeletonCardContent: {
    maxHeight: 'unset !important',
    '@media (max-width: 450px) and (min-width: 400px)': {
      width: '100%'
    }
  },
  outerContentWrapper: {
    paddingBottom: '68px',
    // is smaller than 450px and larger than 400px
    '@media (max-width: 450px) and (min-width: 400px)': {
      display: 'flex'
    }
  },
  cardImage: {
    '@media (max-width: 450px) and (min-width: 400px)': {
      minWidth: '120px',
      display: 'inline-block'
    }
  },
  imageSmallWrapper: {
    margin: '8px auto 8px auto',
    maxWidth: `150px`,
    minWidth: `150px`,
    // if larger than 450px do not show
    display: 'none',
    '@media (min-width: 400px) and (max-width: 450px)': {
      display: 'flex'
    }
  },
  subheader: {
    marginTop: '2px !important',
    fontSize: '10px !important'
  },
  actionButton: {
    backgroundColor: 'white !important'
  },
  actionButtons: {
    display: 'flex  !important',
    // justifyContent: 'flex-end'
    justifyContent: 'space-evenly !important',
    // justifyContent: 'space-between',
    borderTop: '1px solid #0000001a !important',
    margin: '12px 0 0 0 !important',
    padding: '8px 0 8px 0 !important',
    maxWidth: '100% !important',
    flexDirection: 'unset !important' as any,
    minWidth: 'unset !important' as any,
    minHeight: 'unset !important' as any,
    width: '100% !important' as any,
    position: 'absolute !important' as any,
    bottom: '0 !important' as any
  },
  mobileDate: {
    display: 'inline',
    fontSize: '10px !important',
    color: '#333'
  },
  cardHeaderRoot: {},
  cardHeaderContent: {},
  mobileOnly: {
    display: 'inline-block'
  },
  cardRoot2: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    maxWidth: 'calc(100% - 16px)',
    minWidth: 'calc(100% - 16px)',
    minHeight: 'initial'
  },
  listPrice: {
    // strike through
    textDecoration: 'line-through',
    marginLeft: '4px !important',
    verticalAlign: 'bottom',
    display: 'inline !important'
  },
  cardRootDb: {
    display: 'flex !important',
    flexDirection: 'column',
    position: 'relative',
    maxWidth: 'calc(100% - 20px)',
    minWidth: 'calc(100% - 20px)',
    minHeight: 160,
    textAlign: 'left',
    margin: '8px 8px 0 8px',
    boxSizing: 'border-box',

    // Ensure cards stretch to the same height
    flex: '1 0 auto',

    // mobile view
    '@media (min-width: 450px) and (max-width: 699px)': {
      maxWidth: 'calc(50% - 14px)',
      minWidth: 'calc(50% - 14px)',
      minHeight: 'initial',
      '&:nth-of-type(2n)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 700px) and (max-width: 999px)': {
      maxWidth: 'calc(33% - 10px)',
      minWidth: 'calc(33% - 10px)',
      minHeight: 'initial',
      '&:nth-of-type(3n)': {
        marginLeft: 0
      },
      '&:nth-of-type(3n-1)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 1000px) and (max-width: 1399px)': {
      maxWidth: 'calc(25% - 10px)',
      minWidth: 'calc(25% - 10px)',
      minHeight: 'initial',
      '&:nth-of-type(4n)': {
        marginLeft: 0
      },
      '&:nth-of-type(4n-1)': {
        marginLeft: 0
      },
      '&:nth-of-type(4n-2)': {
        marginLeft: 0
      }
    },

    '@media (min-width: 1400px)': {
      maxWidth: 'calc(20% - 10px)',
      minWidth: 'calc(20% - 10px)',
      '&:nth-of-type(5n)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-1)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-2)': {
        marginLeft: 0
      },
      '&:nth-of-type(5n-3)': {
        marginLeft: 0
      }
    }
  },
  chipWrapperDB: {
    marginBottom: '0.35em',
    marginTop: '0.35em',
    display: 'block',
    minHeight: '28px'
  },
  favoriteBorderDB: {
    border: `2px solid ${colors.favorite}`
  },
  bestSellingBorderDB: {
    border: `2px solid ${colors.trending}`
  }
}));
