import React from 'react';
import _ from 'lodash';
import { connect, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import { Grid, Skeleton } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router';

import { AppState, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import Disclaimer from '@components/Disclaimer';
import ScrollTop from '@components/ScrollTop';

import { useDealsState } from './useDealsState';
import DealSortSelect from './components/DealSortSelect';
import DealCard from './components/DealCard';
import DatabaseCard from './components/DatabaseCard';
import SkeletonDealCard from './components/SkeletonDealCard';
import SkeletonDatabaseCard from './components/SkeletonDatabaseCard';
import ReceiptDealContainer from './components/ReceiptDealContainer';
import DatabaseEndMessage from './components/DatabaseEndMessage';
import useStyles from './styles';
import SearchFilterField from './components/SearchFilterField';
import FilterChips from './components/FilterChips';
import DatabaseSettings from './components/DatabaseSettings';
import dealPageConfig from './defaultConfig';

interface DealsProps extends ReturnType<typeof mapStateToProps> {
  isPrimeDay?: boolean;
}

const Deals = (props: DealsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dealSummaryASIN, dealSummary, isPrimeDay } = props;
  const classes = useStyles();
  const funcs = useDealsState(isPrimeDay, dealSummaryASIN);
  const {
    sort,
    onlyCoupons,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    amount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    hasFilters
  } = funcs;

  const CardComponent = shouldShowV2Layout ? DatabaseCard : DealCard;
  const infiniteContainerClass = shouldShowV2Layout
    ? classes.infiniteScrollContainerDatabaseCard
    : classes.infiniteScrollContainer;

  const dealItems = (dealsWithASIN || [])
    .map((props) => {
      return (
        <CardComponent
          {...props}
          key={`${props.title}_${props.ASIN}`}
          toggleOnlyCoupons={toggleOnlyCoupons}
          onlyCoupons={onlyCoupons}
          tag={tagData}
        />
      );
    })
    .slice(0, amount);

  const renderReceiptDealContainer = () => {
    if (dealSummaryASIN) {
      return (
        <ReceiptDealContainer
          dealSummary={dealSummary}
          dealSummaryASIN={dealSummaryASIN}
          dealsWithASIN={dealsWithASIN}
          handleReceiptDealClose={() => {
            dispatch({
              type: DEALS_UPDATE_RECEIPT_DEAL,
              receiptDeal: null
            });

            history.push('/');
          }}
          tag={tagData}
        />
      );
    }

    return null;
  };

  const SkeletonComponent = shouldShowV2Layout
    ? SkeletonDatabaseCard
    : SkeletonDealCard;

  const renderLoader = () => {
    return (
      <>
        {_.times(dealPageConfig.loadingSkeletons, (i) => (
          <SkeletonComponent key={i} />
        ))}
      </>
    );
  };

  const renderInfiniteDeals = () => {
    return (
      <Box className={classes.infiniteContainer}>
        <Box margin="10px 16px 10px 17px">
          <InfiniteScroll
            className={infiniteContainerClass}
            dataLength={dealItems.length} // This is important field to render the next data
            next={() => loadMore()}
            hasMore={
              (dealsWithASIN.length > amount && !latestDealsLoading) ||
              latestDealsLoading
            }
            loader={renderLoader()}
            endMessage={
              <DatabaseEndMessage
                resetAll={resetAll}
                isFiltered={hasFilters()}
              />
            }
          >
            {dealItems}
          </InfiniteScroll>
        </Box>
      </Box>
    );
  };

  const renderSortDeals = () => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth className={classes.sortSelect} size="small">
          <InputLabel id="sort-deals">Sort</InputLabel>
          <DealSortSelect sort={sort} handleSortChange={handleSortChange} />
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      {renderReceiptDealContainer()}
      <Disclaimer />
      <Box marginLeft="-8px">
        <Grid container>
          {renderSortDeals()}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: 'end',
              display: 'flex'
            }}
          >
            <SearchFilterField
              setIsFiltering={setIsFiltering}
              dealsWithASIN={dealsWithASIN}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
            <DatabaseSettings />
          </Grid>
        </Grid>
      </Box>
      <FilterChips
        funcs={{
          ...funcs
        }}
      />
      {(isFiltering || hasFilters()) &&
        dealsWithASIN?.length > 0 &&
        unfilteredDealsWithASIN?.length > 0 && (
          <Typography variant="caption">{`Showing ${dealsWithASIN?.length} of ${unfilteredDealsWithASIN?.length} deals...`}</Typography>
        )}
      {(isFiltering || hasFilters()) &&
        (dealsWithASIN || []).length === 0 &&
        (unfilteredDealsWithASIN || []).length === 0 &&
        latestDealsLoading && <Skeleton height="16px" width="166px" />}
      {renderInfiniteDeals()}
      <ScrollTop />
    </>
  );
};

const mapStateToProps = ({
  home: { receiptDeal, dealSummaryASIN, dealSummary }
}: AppState) => ({
  receiptDeal,
  dealSummaryASIN,
  dealSummary
});

export default connect(mapStateToProps)(Deals);
