import { makeStyles } from '@mui/styles';

export default makeStyles(() => {
  return {
    heartFave: ({ darkMode }: { darkMode: boolean }) => {
      return {
        opacity: 1,
        color: darkMode ? '#cf0b0d' : '#e02b50',
        verticalAlign: 'middle',
        transform: 'translate3d(0, 0, 0)',

        '&:hover': {
          color: darkMode ? '#ffffffbf' : 'initial !important',
          opacity: 0.3,
          cursor: 'pointer'
        }
      };
    },
    heartNotFave: ({ darkMode }: { darkMode: boolean }) => {
      return {
        opacity: 0.3,
        verticalAlign: 'middle',
        transform: 'translate3d(0, 0, 0)',

        '&:hover': {
          color: darkMode ? 'white' : '#e02b50',
          opacity: 1,
          cursor: 'pointer'
        }
      };
    },
    notAllowed: {
      cursor: 'not-allowed'
    }
  };
});
