export default {
  loadPerScroll: 24,
  loadingSkeletons: 24,
  fakeLoadTimeout: 1250,
  defaultSort: 'newest',
  defaultOnlyFavorites: false,
  defaultOnlyShowNew: false,
  defaultOnlyCoupons: false,
  defaultOnlySubscribeSave: false,
  defaultTrending: false,
  defaultPrimeDay: false
};
