import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Button, Chip, Box } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import MonetizingLink from '@components/MonetizingLink';
import { DEALS_UPDATE_RECEIPT_DEAL, DealPostType } from '@types';
import { getPostPrice, getProductLink, formatPrice } from '@utils/index';
import { dealsAction } from '../../../../actions';
import AdminActions from '../../../AdminActions';
import colors from '../../../../utils/colors';
import useStyles from './styles';

const SummaryListDeal = ({
  deal,
  tag = config.AFFILIATE_TAGS.MOST_POPULAR
}: {
  deal: DealPostType;
  tag?: string;
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { couponPercentage, couponFixed, promoFixed, ASIN } = deal;

  const seeOnAmazon = (e: React.MouseEvent) => {
    e.stopPropagation();
    const URL = getProductLink(deal.ASIN, tag);
    trackUse({
      item: 'see-on-amazon',
      value: URL,
      type: 'CLICK'
    });
    window.open(URL, '_blank');
  };

  const image = deal?.image240Url || deal.image;

  return (
    <Grid
      container
      key={deal.ASIN}
      style={{
        marginBottom: 16,
        display: 'flex',
        borderBottom: '1px solid #ccc'
      }}
    >
      <Grid
        item
        sm={12}
        lg={6}
        xl={6}
        style={{
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center' /* aligns child elements horizontally */,
          alignItems: 'center' /* aligns child elements vertically */,
          height:
            '100%' /* should specify a height, otherwise it won't vertically center */
        }}
      >
        {image && (
          <MonetizingLink
            target="_blank"
            href={getProductLink(deal.ASIN, tag)}
            tag={tag}
            clickType="summary-list-deal-image"
          >
            <img
              src={image?.replace('http:', 'https:')}
              alt={deal.title}
              style={{
                maxWidth: '248px',
                maxHeight: '248px',
                marginBottom: '4px'
              }}
            />
          </MonetizingLink>
        )}
      </Grid>
      <Grid item sm={12} lg={6} xl={6}>
        <Typography variant="body2">
          <MonetizingLink
            target="_blank"
            href={getProductLink(deal.ASIN, tag)}
            sx={{
              color: colors.jdbPurple
            }}
            underline="none"
            tag={tag}
            clickType="summary-list-deal-title"
          >
            {deal.title}
          </MonetizingLink>{' '}
          <strong>{getPostPrice(deal, true)}</strong>
        </Typography>
        <Box
          sx={{
            margin: '8px auto 0'
          }}
        >
          {!!deal?.ss && deal.maxSs && (
            <Chip
              id={`ss_chip_${ASIN}`}
              className={`${classes.chip} ${classes.chipSS}`}
              size="small"
              // icon={<AutorenewIcon className={classes.chipSSIcon} />}
              label={`S&S (${deal.maxSs || 5}%)`}
            />
          )}
          {(couponFixed || couponPercentage) && (
            <Chip
              id={`coupon_chip_${ASIN}`}
              className={`${classes.chip} ${classes.chipCoupon}`}
              size="small"
              // icon={<SellIcon className={classes.chipCouponIcon} />}
              label={`${
                couponPercentage
                  ? `${couponPercentage}%`
                  : `${formatPrice(couponFixed)}`
              } Coupon`}
            />
          )}
          {promoFixed && (
            <Chip
              id={`promo_chip_${ASIN}`}
              className={`${classes.chip} ${classes.chipPromo}`}
              size="small"
              // icon={<SellIcon className={classes.chipPromoIcon} />}
              label={`${formatPrice(promoFixed)} Discount`}
            />
          )}
        </Box>
        <div style={{ marginTop: '4px' }} />
        <Box display="flex" justifyContent="flex-end" alignItems="right">
          <Button
            className={classes.actionButton}
            size="small"
            startIcon={<ReceiptLongIcon />}
            onClick={() => {
              // if on a pageurl that doesn't contain "deal"
              // then add it to the url
              if (!window.location.href.includes('/deal/')) {
                window.location.href = `/deal/${deal.ASIN}`;
                trackUse({
                  item: 'details-button-summary-click',
                  value: deal.ASIN,
                  type: 'CLICK'
                });
              } else {
                dispatch({
                  type: DEALS_UPDATE_RECEIPT_DEAL,
                  receiptDeal: deal
                });
                dispatch(dealsAction.getDealSummary(deal.ASIN));
                trackUse({
                  item: 'details-button-click',
                  value: deal.ASIN,
                  type: 'CLICK'
                });
              }
            }}
          >
            Details
          </Button>
          <Button
            key="btn-2"
            size="small"
            startIcon={<LaunchIcon />}
            onClick={seeOnAmazon}
            variant="outlined"
            className={classes.seeOnAmazonButton}
          >
            Amazon
          </Button>
          <AdminActions ASIN={ASIN} deal={deal} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default SummaryListDeal;
