import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  searchFilter: {
    margin: '10px 4px 0px 4px !important',
    width: 'calc(100% - 8px) !important',
    '@media (max-width: 600px)': {
      margin: '10px 4px 6px 4px !important'
    }
  }
}));
