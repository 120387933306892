import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  loading: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    margin: '30px auto',
    minHeight: '50px',
    '@media (max-width: 48rem)': {
      margin: '30px auto'
    }
  },
  titleQty: {
    marginBottom: '8px'
  },
  disclaimer: {
    fontStyle: 'italic',
    color: '#595959',
    fontSize: '11px'
  },
  sortSelect: {
    margin: '10px 4px 0px 4px !important',
    width: 'calc(100% - 8px) !important'
  },
  infiniteScrollContainer: {
    marginLeft: '-17px',
    marginRight: '-17px',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: '12px'
  },
  infiniteScrollContainerDatabaseCard: {
    marginLeft: '-17px',
    marginRight: '-17px',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: '12px'
  },
  paper: {
    overflow: 'visible !important'
  },
  infiniteContainer: {
    backgroundColor: 'rgba(128, 128, 128, 0.16)',
    marginLeft: '-16px',
    marginRight: '-17px'
  }
}));
